import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  IconButton,
  Text,
  useToast,
} from '@chakra-ui/react';
import { navigate } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import LinkButton from '../components/buttons/link-button';
import SEO from '../components/seo';
import RecommendedProducts from '../components/shop/recommended-products';
import { useAddItemToCart } from '../context/StoreContext';
import seedrandom from 'seedrandom';

function ProductPage({
  pageContext: { title, description, brand, price, image, type, shopifyId },
}) {
  const allProducts = useStaticQuery(graphql`
    query relatedProducts {
      allShopifyProduct(limit: 20) {
        nodes {
          title
          vendor
          priceRangeV2 {
            maxVariantPrice {
              amount
            }
          }
          productType
          media {
            ... on ShopifyMediaImage {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 400)
                  }
                }
              }
            }
          }
        }
      }
    }
  `).allShopifyProduct.nodes;

  const randomNumber = seedrandom(title);

  const shuffle = (array) => {
    const sortedArray = array.sort(() => randomNumber - 0.5);
    return sortedArray.slice(0, 4);
  };

  const relatedProducts = shuffle(allProducts);

  console.log('Related Products: ', relatedProducts);

  const toast = useToast();
  const addItemToCart = useAddItemToCart();

  const [productQuantity, setProductQuantity] = useState(1);

  const increaseQuantity = () => {
    if (productQuantity >= 5) {
      return;
    }
    setProductQuantity((prev) => prev + 1);
  };

  const decreaseQuantity = () => {
    if (productQuantity <= 0) {
      return;
    }
    setProductQuantity((prev) => prev - 1);
  };

  const addToCart = async () => {
    await addItemToCart(shopifyId, productQuantity);
    toast({
      title: 'Added to cart',
      status: 'success',
      duration: 5000,
    });
  };

  const buyNow = async () => {
    await addItemToCart(shopifyId, productQuantity);
    navigate('/cart');
  };

  return (
    <>
      <SEO title={`${title}`} />
      <Flex flexDir="column" w="100%">
        <Flex my={{ base: 4, lg: 8 }} w="100%" flexDir="column">
          <LinkButton text="Back to shop" link="shop" iconPosition="left" />
          <Divider mt={4} w="100%" />
        </Flex>
        <Flex
          my={{ base: 4, lg: 8 }}
          flexDir={{ base: 'column', lg: 'row' }}
          alignItems={{ base: 'flex-start' }}
        >
          <Flex flexDir="column" w={{ base: '100%', lg: '40%' }}>
            <Flex flexDir="row" w="100%">
              <Box w={{ base: '50%', lg: '100%' }} overflow="hidden">
                <Img image={image} alt={title} />
              </Box>
              <Flex
                flexDir="column"
                alignItems="start"
                w="100%"
                pl={{ base: 6, lg: 8 }}
              >
                <Text
                  color="gray.800"
                  fontSize="xs"
                  fontWeight="semibold"
                  bgColor="gray.200"
                  py={0.5}
                  px={3}
                  borderRadius="2xl"
                  cursor="pointer"
                >
                  {type || 'Resource'}
                </Text>
                <Heading as="h1" my={{ base: 2, lg: 4 }} fontSize="2xl">
                  {title}
                </Heading>
                <Text color="gray.500" fontSize="md">
                  {type.toLowerCase() === 'book' ? 'By ' : ''}
                  {brand}
                </Text>
                <Flex
                  my={4}
                  py={1}
                  px={4}
                  borderRadius="3xl"
                  border="1px solid"
                  borderColor="gray.200"
                  bgColor="white"
                >
                  <Text
                    color="gray.800"
                    fontWeight="semibold"
                    fontSize="md"
                  ></Text>
                  ${Number(price).toFixed(2)}
                </Flex>
              </Flex>
            </Flex>
            <Flex
              flexDir="column"
              mt={8}
              w={{ base: '100%', lg: '50%' }}
              display="flex"
            >
              <Flex justifyContent="space-between" alignItems="center">
                <IconButton
                  icon={<MinusIcon />}
                  aria-label="Remove item"
                  onClick={decreaseQuantity}
                />
                <Text color="gray.800" fontWeight="semibold">
                  {productQuantity}
                </Text>
                <IconButton
                  icon={<AddIcon />}
                  aria-label="Add item"
                  onClick={increaseQuantity}
                />
              </Flex>
              <Button colorScheme="primary" my={4} onClick={buyNow}>
                Buy now
              </Button>
              <Button
                colorScheme="primary"
                variant="outline"
                onClick={addToCart}
                mb={4}
              >
                Add to cart
              </Button>
            </Flex>
          </Flex>
          <Text
            color="gray.800"
            lineHeight={1.5}
            ml={{ base: 0, lg: 20 }}
            mt={{ base: 8, lg: 4 }}
            w={{ base: '100%', lg: '50%' }}
          >
            {description}
          </Text>
        </Flex>
        <RecommendedProducts relatedProducts={relatedProducts} />
        <Flex my={8} w="100%" flexDir="column">
          <Divider mb={4} w="100%" />
          <LinkButton text="Back to shop" link="shop" iconPosition="left" />
        </Flex>
      </Flex>
    </>
  );
}

export default ProductPage;
