import { Divider, Flex, Grid, Heading } from '@chakra-ui/react';
import { getImage } from 'gatsby-plugin-image';
import React from 'react';
import slugify from 'slugify';
import ProductPreview from './product-preview';

function RecommendedProducts({ relatedProducts }) {
  return (
    <>
      <Flex flexDir="column" my={{ base: 12, lg: 20 }} w="100%">
        <Heading as="h3" fontSize="1.6rem">
          You might also like
        </Heading>
        <Divider mb={12} mt={4} />
        <Grid
          gridTemplateColumns={{
            base: 'repeat(2, 1fr)',
            md: 'repeat(4, 1fr)',
          }}
          gap={{ base: 8, md: 12, lg: 32 }}
          w="100%"
        >
          {relatedProducts.map((product, index) => {
            const slug = product.title
              .replace(
                /[(?!.*--)`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
                ''
              )
              .replace(/[(?!.*--)\s]/gi, '-')
              .toLowerCase();
            return (
              <ProductPreview
                key={index}
                name={product.title}
                type={product.productType}
                image={
                  product.media[0]?.image?.localFile?.childImageSharp
                    .gatsbyImageData
                }
                brand={product.vendor}
                price={product.priceRangeV2.maxVariantPrice.amount}
                path={`${slug}`}
              />
            );
          })}
        </Grid>
      </Flex>
    </>
  );
}

export default RecommendedProducts;
